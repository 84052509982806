
export type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyBzvr9bN5M2lgzTLIo07wn3bUmmZnsMhaA',
  authDomain: 'codeleap-project-template.firebaseapp.com',
  databaseURL: 'https://codeleap-project-template.firebaseio.com',
  projectId: 'codeleap-project-template',
  storageBucket: 'codeleap-project-template.appspot.com',
  messagingSenderId: '268760770384',
  appId: '1:268760770384:web:49a825eb74a7b626d1ee55',
}

export let cachedFirebaseInstance: FirebaseInstance = null

export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedFirebaseInstance = _firebase
    initialized = true
  }

  return _firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
