import { navigation, React } from '@/app'
import { View, Button, Link, Avatar, Text } from '@/components'
import { getNestedStylesByKey, StylesOf, useI18N } from '@codeleap/common'
import { APIClient } from '@/services'
import { HeaderComposition } from '../../app/stylesheets/Header'

export type NavContentProps = {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

const links: NavigateLink[] = [
  { text: 'Components', route: 'Components.List' },
  { text: 'CRUD', route: 'Crud.Example' },
  { text: 'About', route: 'About' },
]

export const NavContent = (props: NavContentProps) => {
  const { isMobile, variantStyles } = props
  const { isLoggedIn, profile } = APIClient.Session.useSession()
  const { t } = useI18N()

  const signInButtonStyles = getNestedStylesByKey('signInButton', variantStyles)
  const avatarStyles = getNestedStylesByKey('avatar', variantStyles)

  const ProfileAvatar = React.useCallback(() => (
    <Avatar
      image={profile?.avatar as any}
      name={[profile?.first_name, profile?.last_name]}
      styles={avatarStyles}
      debugName={'header:avatar'}
      onPress={() => navigation.navigate('Profile.Edit')}
    />
  ), [profile])

  const renderLink = React.useCallback((link: NavigateLink) => {
    const isSelected = navigation.isCurrentRoute(link?.route)

    return (
      <Link
        key={'header-' + link?.text}
        text={link?.text}
        route={link?.route}
        to={link?.to}
        css={[variantStyles?.navItem, isSelected ? variantStyles['navItem:selected'] : {}]}
      />
    )
  }, [])

  return (
    <View css={variantStyles.navContentWrapper}>
      {(!!isLoggedIn && isMobile) ? (
        <View css={variantStyles.profileWrapper}>
          <ProfileAvatar />

          <View css={variantStyles.profileInfos}>
            <Text text={profile?.first_name} css={variantStyles.firstName} />
            <Text text={profile?.email} css={variantStyles.email} />
          </View>
        </View>
      ) : null}

      {links?.map(renderLink)}

      {!!isLoggedIn ? null : (
        <Button
          text={t('actions.signup')}
          styles={signInButtonStyles}
          onPress={() => navigation.navigate('Auth.Signup')}
          debugName='header:signUpButton'
        />
      )}

      {(!!isLoggedIn && !isMobile) ? <ProfileAvatar /> : null}
    </View>
  )
}
