import { View, Text, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'

export type FooterDisclaimersProps = {
  variantStyles?: StylesOf<FooterComposition>
}

export const FooterDisclaimers = (props: FooterDisclaimersProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.disclaimersWrapper}>
      <Text css={variantStyles.disclaimerText}>Visit us in Camden, London, UK.</Text>
      <Text css={variantStyles.disclaimerText}>
        CodeLeap Ltd. is registered in England&nbsp;&&nbsp;Wales,
        company&nbsp;no.&nbsp;11967804.
      </Text>
      <Text css={variantStyles.disclaimerText}>
        This website uses&thinsp;
        <Link css={variantStyles.disclaimerText} to='/terms/license'>third-party assets and software
        </Link>
        . By using our website you agree to the&thinsp;
        <Link css={variantStyles.disclaimerText} to='/terms/website'>
          terms of use
        </Link>
        .
      </Text>
    </View>
  )
}
