import { React, Settings, AppImages } from '@/app'
import { View, CenterWrapper, Footer, Header, Image } from '@/components'
import SEO, { SEOProps } from './SEO'
import { ComponentVariants, PropsOf, StylesOf, useDefaultComponentStyle, useNestedStylesByKey } from '@codeleap/common'
import { useUpdateLocale } from '@/utils'
import { PageComposition, PageStyles } from '../app/stylesheets/Page'

export type PageProps = {
  showHeader?: boolean
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageProps?: Partial<PropsOf<typeof Image>>
  pageTitle?: string
  appendNameToPageTitle?: boolean
  centerContent?: boolean
  SEOProps?: Partial<SEOProps>
  className?: string
  contentProps?: Partial<PropsOf<typeof CenterWrapper>>
  styles?: StylesOf<PageComposition>
  style?: React.CSSProperties
  children: React.ReactNode
} & ComponentVariants<typeof PageStyles>

const defaultProps: Partial<PageProps> = {
  showHeader: true,
  showFooter: true,
  appendNameToPageTitle: false,
  centerContent: true,
  showBackgroundImage: false,
  backgroundImageSource: AppImages.LogoImage,
  SEOProps: {},
}

export const Page = (props: PageProps) => {
  const allProps = {
    ...Page.defaultProps,
    ...props,
  }

  const {
    showHeader,
    showFooter,
    pageTitle,
    appendNameToPageTitle,
    centerContent,
    showBackgroundImage,
    backgroundImageSource,
    backgroundImageProps,
    SEOProps,
    className,
    children,
    variants,
    responsiveVariants,
    styles,
    contentProps,
    style,
    ...rest
  } = allProps

  const variantStyles = useDefaultComponentStyle<'u:Page', typeof PageStyles>('u:Page', {
    responsiveVariants,
    rootElement: 'wrapper',
    styles,
    variants,
  })

  const contentStyles = useNestedStylesByKey('content', variantStyles)
  const footerStyles = useNestedStylesByKey('footer', variantStyles)
  const headerStyles = useNestedStylesByKey('header', variantStyles)

  useUpdateLocale()

  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const SEOTitle = pageTitle && `${pageTitle} ${appendedTitle}`

  const innerWrapperStyles = React.useMemo(() => ([
    variantStyles.innerWrapper,
    variantStyles['innerWrapper:backgroundImage']
  ]), [])

  const InnerWrapper = centerContent ? CenterWrapper : React.Fragment

  return (
    <View css={[variantStyles.wrapper, style]} className={className} {...rest}>
      <SEO title={SEOTitle} {...SEOProps} />

      {showBackgroundImage ? (
        <Image
          source={backgroundImageSource}
          style={variantStyles.backgroundImage}
          {...backgroundImageProps}
        />
      ) : null}

      <View css={innerWrapperStyles}>
        {showHeader ? <Header styles={headerStyles} /> : null}
        <InnerWrapper styles={contentStyles} {...contentProps}>
          {children}
        </InnerWrapper>
        {showFooter ? <Footer styles={footerStyles} /> : null}
      </View>
    </View>
  )
}

Page.defaultProps = defaultProps
